<template>
  <div>
    <v-container mt-7 pa-6 v-if="!isLoading">
      <v-row>
        <v-col>
          <h1 class="display-1 mb-2">{{ article.name }}</h1>
        </v-col>
      </v-row>

      <!--Article -->
      <v-card class="pa-7 mt-3">
      <v-row justify="center">
        <v-col xl="4">
          <div v-if="selected !== null" >
            <v-img
              v-if="selected.variantImage !== null"
              :src="selected.variantImage"
              width="444px"
            ></v-img>
            <v-img v-else :src="article.articleImage" width="444px"></v-img>
          </div>
          <v-img v-else :src="article.articleImage" width="444px"></v-img>
        </v-col>
        <v-col xl="5">
          <div class="mt-6">
            <v-card-title v-if="article.price > 0">
              <span class="display-2">{{ article.price }} :-</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="article.standardSellPrice > 0">
              <span
                >Rekommenderat pris: {{ article.standardSellPrice }} :-</span
              >
            </v-card-text>
            <v-card-title>Beskrivning</v-card-title>
            <v-card-text
              >Återförsäljare: {{ article.supplierName }}</v-card-text
            >
            <v-card-text>
              <span v-html="article.description"></span>
            </v-card-text>
            <v-divider></v-divider>
            <div class="pa-2">
              <p :class="statusColor">{{ stockStatus }}</p>
              <v-select
                :items="article.articleVariants"
                item-text="name"
                label="Välj variant"
                outlined
                return-object
                v-model="selected"
              ></v-select>
              <div v-if="selected !== null">
                <v-row>
                  <v-col sm="4" xs="2">
                    <v-text-field
                      v-show="stockStatus !== 'Finns ej i lager'"
                      outlined
                      type="number"
                      v-model.number="amount"
                      label="Antal"
                      min="1"
                      :max="selected.storageAmount"
                    />
                  </v-col>
                </v-row>
                <div>
                  <v-text-field
                    outlined
                    :label="article.commentName"
                    v-model="comment"
                    v-show="
                      stockStatus !== 'Finns ej i lager' &&
                      article.isCommentable
                    "
                  ></v-text-field>
                </div>

                <div
                  v-if="
                    (stockStatus !== 'Finns ej i lager' &&
                      amount > 0 &&
                      !article.isCommentable) ||
                    (article.isCommentable &&
                      comment.length > 0 &&
                      stockStatus !== 'Finns ej i lager' &&
                      amount > 0)
                  "
                >
                  <v-btn color="success" @click="addToCart"
                    >Lägg till i kundvagn</v-btn
                  >
                </div>

                <v-btn v-else color="success" disabled
                  >Lägg till i kundvagn</v-btn
                >
              </div>

              <v-btn v-else color="success" disabled class="mb-2"
                >Lägg till i kundvagn</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
      </v-card>
     
    </v-container>
    <v-container v-else>
      <v-row class="text-center">
        <v-col>
          <v-progress-circular
            :size="50"
            color="secondary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      selected: null,
      amount: 0,
      comment: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      article: (state) => state.article.article,
      cart: (state) => state.cart.cart
    }),
    stockStatus() {
      if (this.selected === null) {
        if (this.article.storageAmount > 0) {
          return "Finns i lager";
        }
      } else if (this.selected.storageAmount > 0) {
        return "Finns i lager";
      }

      return "Finns ej i lager";
    },
    statusColor() {
      if (this.stockStatus === "Finns i lager") {
        return "success--text";
      }
      return "red--text";
    },
  },
  created() {
    this.$store.dispatch("article/getArticle", {
      id: this.$route.params.id,
      articleId: this.$route.params.articleId,
    });
  },
  watch: {
    article(newVal) {
      if (newVal.articleId !== this.article.articleId) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    },
  },
  methods: {
    addToCart() {
      const articleToCart = {
        article: this.article,
        selectedVariant: this.selected,
        quantity: 0,
      };

      articleToCart.article.comment = this.comment;

      const total =
        this.amount > articleToCart.selectedVariant.storageAmount
          ? articleToCart.selectedVariant.storageAmount
          : this.amount;

      articleToCart.quantity = total;

      for (let i = 0; i < total; i++) {
        this.$store.dispatch("cart/addToCart", articleToCart);
      }
      this.$store.commit("updateSnackbar", {
        show: true,
        message: `${total}st ${this.article.name} tillagd i kundvagnen`,
      });
    },
  },
};
</script>
