<template>
  <v-card v-if="cartCompact">
    <v-list two-line v-if="itemData.length > 0">
      <v-list-item v-for="(item, index) in cartCompact" :key="index">
        <v-list-item-avatar>
          <v-img :src="item.image"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-group @click="getCartItems(item)">
            <template v-slot:activator>
              <v-list-item-title class="hidden-xs-only"
                >{{ item.name }} - {{ item.selectedVariant }}</v-list-item-title
              >
              <v-list-item-subtitle class="hidden-sm-and-up">
                {{ item.name }}
                <br />
                {{ item.selectedVariant }}
                <br />
                Antal:
                {{ itemData[index].amount }}
              </v-list-item-subtitle>
              <span class="hidden-xs-only"
                >Antal: {{ itemData[index].amount }}</span
              >
            </template>
            <div
              v-for="(cartItem, _index) in filteredCartItems"
              :key="cartItem.cartId"
            >
              <CartItem
                :cartItem="cartItem"
                :Index="_index"
                @removeFromCart="removeFromCart($event)"
              ></CartItem>
            </div>
          </v-list-group>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
  <div v-else>Laddar artiklar...</div>
</template>

<script>
import CartItem from "../cart/CartItem";

export default {
  props: {
    cartItems: Array,
    cartCompact: Array,
  },
  components: {
    CartItem,
  },
  data() {
    return {
      filteredCartItems: [],
      itemData: [],
    };
  },
  methods: {
    getCartItems(item) {
      this.filteredCartItems = this.cartItems.filter(
        (i) =>
          i.name === item.name && i.selectedVariant === item.selectedVariant
      );
    },
    getCartItemsCount(item) {
      return this.cartItems.filter(
        (i) =>
          i.name === item.name && i.selectedVariant === item.selectedVariant
      ).length;
    },

    removeFromCart(item) {
      const index = this.cartItems.indexOf(item);
      this.filteredCartItems = this.filteredCartItems.filter(
        (i) => i.cartId !== item.cartId
      );
      this.$store.dispatch("cart/deleteFromCart", index);

      this.itemData = [];
      for (var i of this.cartCompact) {
        this.itemData.push({ amount: this.getCartItemsCount(i) });
      }
    },
  },
  mounted() {
    for (var item of this.cartCompact) {
      this.itemData.push({ amount: this.getCartItemsCount(item) });
    }
  },
};
</script>

<style></style>
