<template>
  <div>
      <div>
        <v-autocomplete
            v-model="selected"
            :items="servicePoints"
            label="Sök utlämningsställe"
            item-text="pickupLocation.locationName"
            :search-input.sync="search"
            item-value="id"
            return-object
            no-data-text="Skriv stad"
            cache-items
            :loading="isLoading"
            :rules="rules"
        ></v-autocomplete>
      </div>
      <div v-if="selected">
          <br />
          <h4>VALT UTLÄMNINGSTÄLLE</h4>
          <br />
          <p><strong>{{selected.pickupLocation.locationName}}</strong> <br />
           {{selected.pickupLocation.address}} <br />
           {{selected.pickupLocation.postalCode}} {{selected.pickupLocation.city}} 

          </p>

      </div>
      <br />
  </div>
</template>

<script>
import services from "../../services"
export default {
    props: ["rules"],
    methods: {
        async getServicePointsByCity(city) {
            var res = await services.getServicePoint(city)
            return res    
        },
        async getServicePointsbyGeo(position) {
            this.isLoading = true
            var res = await services.getServicePoint(null, position.coords.latitude, position.coords.longitude)
            this.servicePoints = res
            this.selected = res[0]
            this.isLoading = false
        },
        getGeoLocation() {          
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.getServicePointsbyGeo);
            }
        }
    },
    data() {
        return {
            servicePoints : [],
            selected : null,
            search : "",
            isLoading : false
        }
    },
    watch: {
      async search (newVal, oldVal) {

        if (!newVal || !oldVal) return

        // Items have already been loaded

        if (newVal === oldVal || newVal.length < 3) return

        this.isLoading = true

        // Lazily load input items
        var result = await this.getServicePointsByCity(newVal)
        this.servicePoints = result    
        this.isLoading = false
          
      },
      selected (newVal) {
           this.$emit('selectedPickupLocation', newVal)
      }
    },
    async mounted() {
        await this.getGeoLocation()      
    }

}
</script>

<style>

</style>