<template>
  <div>
    <v-btn fab fixed bottom right color="success" @click="goToCart">
      <v-icon>mdi-cart</v-icon>
      <v-badge
        color="primary"
        offset-y="-12"
        :content="amountInCart.toString()"
      ></v-badge>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    amountInCart: Number,
  },

  methods: {
    goToCart() {
      this.$router.push("/" + this.shop.slug + "/cart");
    },
  },
  computed: {
    ...mapState({
      shop: (state) => state.webshop.shop,
    }),
  },
};
</script>

<style></style>
