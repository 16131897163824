<template>
  <div>
    <v-dialog v-model="dialog" width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="primary"
          plain
          small
          :block="!!block"
          elevation="3"
          v-bind="attrs"
          v-on="on"
          >{{ linkText }}</v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Villkor</span>
        </v-card-title>
        <v-card-text>
          <div v-html="shop.terms"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false"
            >Stäng</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    linkText: String,
    block: Boolean,
  },
  computed: {
    ...mapState({
      shop: (state) => state.webshop.shop,
    }),
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style>
</style>