import services from "../../services";

const getDefaultState = () => {
  return {
    order: {},
    orderFullSize: {},
    isloading: false,
    errorMessage: "",
    shippingFee : 139,
    freeShippingLimit: 950
  };
};

const state = getDefaultState();

const actions = {
  setDefaultState: ({ commit }) => {
    commit("setDefaultState");
  },
  getOrder({ commit }, { id, orderNumber }) {
    return new Promise((resolve, reject) => {
      services.getOrder(id, orderNumber).then(
        (order) => {
          commit("setOrder", order);
          commit("setOrderFull", order);
          resolve(order);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  createOrder: ({ commit }, { slug, order }) => {
    state.isloading = true;
    return new Promise((resolve, reject) => {
      services.submitOrder(slug, order).then(
        (item) => {
          resolve(item);
          commit("createOrder", item);
          order.orderNumber = item.order_number;
          localStorage.setItem("order", JSON.stringify(order));
          commit("setOrderFull", order);
        },
        (error) => {
          reject(error);
          state.isloading = false;
          state.errorMessage = "Något blev fel, vänligen försök igen.";
          console.log(error);
        }
      );
    });
  },
  updateOrder: ({ commit }, { newOrderObj }) => {
    return new Promise((resolve, reject) => {
      services.updateOrder(newOrderObj).then(
        (item) => {
          commit("setOrderFull", item);
          resolve(item);
        },
        (error) => {
          reject(error);
          console.log(error);
          (state.isloading = false), (status.errorMessage = "Fel!");
        }
      );
    });
  },
  confirmOrder: ({ commit }, order) => {
    return new Promise((resolve, reject) => {
      services.confirmOrder(order).then(
        (item) => {        
          commit("confirmOrder", item);
          resolve(item);
        },
        (error) => {
        
          reject(error);
          console.log(error);       
          (state.isloading = false), (status.errorMessage = "Något gick fel!");
        }
      );
    });
  }
};

const mutations = {
  createOrder: (state, order) => {
    state.order = order;
  },
  setOrder: (state, order) => {
    state.order = order;
  },
  setOrderFull: (state, orderFull) => {
    state.orderFullSize = orderFull;
  },
  setShippingFee: (state, fee) => {
    state.shippingFee = fee;
  },
  confirmOrder: (state, order) => {
    state.loading = false;    
      state.order = order;   
  }
};

export default {
  namespaced: true,
  state,

  actions,
  mutations
};
