import services from "../../services";

const state = {
  article: []
};

const getters = {
  article: (state) => {
    return state.article;
  }
};

const actions = {
  getArticle({ commit }, { id, articleId }) {
    return new Promise((resolve, reject) => {
      services.getArticle(id, articleId).then(
        (article) => {
          commit("setArticle", article);
          resolve(article);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
};

const mutations = {
  setArticle(state, article) {
    state.article = article;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
