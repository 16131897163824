<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <p class="display-1">{{ content }}</p></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["hppId", "order_id", "orderNumber", "orderEmail"],
  async created() {
    if (
      !this.hppId ||
      !this.order_id ||
      !this.orderNumber ||
      !this.orderEmail
    ) {
      this.content =
        "Hoppsan! Något gick fel. Titta gärna i din epost om ordern har gått igenom. (Du bör ha fått ett bekräftelsemail)";
    } else {
      //Everything looks ok, continue
      await this.$store.dispatch("order/getOrder", {
        id: this.$route.params.id,
        orderNumber: this.orderNumber,
      });

      await this.$store.dispatch("order/confirmOrder", this.order);
      localStorage.setItem("cart", JSON.stringify([]));
      await this.$store.dispatch("cart/setDefaultState", []);
      this.$router.push(
        `/${this.$route.params.id}/order/${this.order.orderNumber}`
      );
    }
  },

  computed: {
    ...mapState({
      order: (state) => state.order.order,
    }),
  },
  data() {
    return {
      content: "Hanterar din beställning. Stäng inte ner webbläsarfönstret",
    };
  },
};
</script>

<style>
</style>