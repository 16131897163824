<template>
  <div>
    <v-container>
      <v-row>
        <!--Articles-->
        <v-col xs="10" sm="8" md="8" offset-md="2" lg="5" class="ma-2">
          <h2>Kundvagn ({{ cartSize }})</h2>
          <v-row> </v-row>
          <v-row>
            <div v-show="cartSize <= 0" width="500" class="ma-2">
              <h4>Inga produkter...</h4>
            </div>
          </v-row>
          <div>
            <CartItemsList
              :cartItems="cart"
              :cartCompact="cartCompact"
            ></CartItemsList>
          </div>
        </v-col>
        <v-col
          order="last"
          order-sm="last"
          order-xs="last"
          v-show="cartSize > 0"
        >
          <h2>Betalning</h2>
          <CheckoutBox :TotalPrice="cartTotalAmount"></CheckoutBox>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CheckoutBox from "../components/cart/CheckoutBox";
import CartItemsList from "../components/cart/CartItemsList";
import { mapState } from "vuex";
import { mapGetters } from "vuex";

export default {
  components: {
    CheckoutBox,
    CartItemsList,
  },
  computed: {
    ...mapState({
      cart: (state) => state.cart.cart,
    }),
    ...mapGetters({
      cartSize: "cart/cartSize",
      cartTotalAmount: "cart/cartTotalAmount",
      cartCompact: "cart/cartCompact",
    }),
  },
};
</script>
