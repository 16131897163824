import Vue from "vue";
import Vuex from "vuex";
import webshop from "./modules/webshop";
import article from "./modules/article";
import cart from "./modules/cart";
import order from "./modules/order";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    webshop,
    article,
    cart,
    order
  },
  state: {
    snackbar: {
      show: false,
      variant: "success",
      message: ""
    }
  },
  mutations: {
    updateSnackbar(state, val) {
      state.snackbar.show = val.show;
      state.snackbar.message = val.message;
    }
  }
});
