import axios from "axios";

export default axios.create({

  baseURL: process.env.VUE_APP_SITE_URL,
  //timeout: 20000,
  headers: {
    "Content-Type": "application/json"
  }
});


//https://retailhutlegacy-app-service.azurewebsites.net/api/client
//https://localhost:44374/api/client/