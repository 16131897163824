<template>
  <v-card @click="goToArticle">
    <v-img :src="article.articleImageThumb == null ? article.articleImage : article.articleImageThumb" height="210" contain />

    <v-card-text class="text-center">
      <v-divider></v-divider>
      <small>{{ article.supplierName }}</small>

      <p class="text-center" style="overflow-y: auto; height:45px">
        <b>{{ article.name }}</b>
      </p>
    </v-card-text>
    <v-card-text class="text-center" v-if="!$store.state.webshop.shop.isShowroom">
      <h1 class="display-1 text--primary">{{ article.price }} :-</h1>
      <p v-if="article.standardSellPrice > 0">Rekommenderat pris: {{ article.standardSellPrice }} :-</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    article: Object,
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    goToArticle() {
      let wshop = this.$store.state.webshop.shop;
      let artToGoTo = wshop.allArticles.find((i) => i.id == this.article.id);
      this.$router.push(`/${wshop.slug}/article/${artToGoTo.slug}`);
    },
  },
  mounted() {},
};
</script>

<style></style>
