import Cart from "./views/Cart";
import Home from "./views/Home";
import Article from "./views/Article";
import Order from "./views/Order";
import KlarnaRedirect from "./views/KlarnaRedirect"

import Vue from "vue";
import VueRouter from "vue-router";

const routes = [
  {
    path: "/:id",
    name: "home",
    component: Home,
    beforeEnter(to, from, next) {      
    
      next()
     }
  },

  { path: "/:id/cart", name: "cart", component: Cart },
  { path: "/:id/article/:articleId", name: "article", component: Article },
  { path: "/:id/order/:orderNumber", name: "order", component: Order },
  {
    path: '/:id/redirect',
    component: KlarnaRedirect,
    props(route) {
      return  route.query || {}
    }
  }
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes
});

export default router;
