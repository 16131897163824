<template>
  <div>
    <v-row>
      <v-col>
        <h2>Beställning</h2>
        <v-list dense>
          <v-list-item three-line v-for="article in denseList" :key="article.cartId">
            <v-list-item-content>
              <v-list-item-title>
                <h3>x {{article.quantity}}</h3>
                {{ article.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ article.selectedVariant }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-dialog v-model="dialog" width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">Visa detaljer</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Artiklar ({{articleList.length}})</span>
            </v-card-title>
            <v-card-text>
              <v-list>
                <template v-for="(article, index) in articleList">
                  <v-divider :key="index"></v-divider>
                  <v-list-item :key="article.cartId">
                    <v-list-item-avatar>
                      <v-avatar>
                        <img :src="article.image" :alt="article.name" />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="text--secondary">#{{index + 1 }}</span>
                        {{article.name}}
                      </v-list-item-title>
                      <v-list-item-title>{{ article.selectedVariant }}</v-list-item-title>
                      <v-list-item-subtitle>{{article.comment}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">Stäng</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col>
        <h2>Mottagare</h2>
        <p>{{ reciever.email }}</p>
        <p>{{ reciever.phone }}</p>
        <br />
        <h2>Pris</h2>
        <p class="display-1">{{totalPrice}} :-</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as _ from "underscore";
export default {
  props: {
    articleList: Array,
    reciever: Object,
    totalPrice: Number,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    denseList() {
      return _.uniq(this.articleList, (i) => {
        return i.name && i.selectedVariant;
      });
    },
  },
};
</script>

<style>
</style>