<template>
  <div v-if="shop">
    <div v-if="shop.isOnline">
      <Header :Name="shop.name" :Description="shop.description"></Header>
      <div>
        <terms-modal linkText="Läs Butiksvillkor" block="true"></terms-modal>
      </div>
      <v-col>
          <v-row
                align="center"
                justify="center"
                class="mt-2 hidden-md-and-up text-center"
                >{{ shop.description }} </v-row
              >
      </v-col>
      <div> 
          <PopularProducts></PopularProducts>
      </div>
    </div>
    <div v-else>
      <v-container>
        <v-col>
          <v-row align-content="center" justify="center">
            <h1>{{ shop.name}} </h1>
          </v-row>
        <v-row align-content="center" justify="center">
          <p class="text--secondary text-xl-body-1 text-center">{{shop.description}}'
          </p>
        </v-row>
      <v-row align-content="center" justify="center" class="mt-4">
          <strong class="text-center">{{shop.message}}</strong>
        </v-row>
        <v-row class="mt-5" align-content="center" justify="center">
           <p>Kontakt: mats@ebreklam.se</p>
        </v-row>
        </v-col>
      </v-container>
    </div>
  </div>
  <div v-else>
    <v-container>
      <v-row align-content="center" justify="center">
        <v-progress-circular
          :size="75"
          color="primary"
          indeterminate
          class="mt-10"
        ></v-progress-circular
      ></v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "../components/home/Header";
import PopularProducts from "../components/home/PopularProducts";
import TermsModal from "../components/TermsModal.vue";
import { mapState } from "vuex";

export default {
  components: {
    Header,
    PopularProducts,
    TermsModal,
  },
  computed: {
    ...mapState({
      shop: (state) => state.webshop.shop,
    }),
  },
};
</script>

<style></style>
