<template>
  <v-card class="ma-2">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="mb-1"
          >#{{ Index + 1 }} - {{ art.selectedVariant }}</v-list-item-title
        >
        <div v-if="art.isCommentable">
          <div v-if="edit">
            <v-text-field
              dense
              outlined
              v-model="art.comment"
              height="75"
              class="mt-3"
            ></v-text-field>
          </div>
          <div v-else>
            <small>{{ art.commentName }}: </small>
            <p>{{ art.comment }}</p>
          </div>

          <v-btn @click="saveChangesOnItem()">{{
            edit ? "Spara" : "Ändra"
          }}</v-btn>
        </div>
      </v-list-item-content>
      <v-btn
        class="error mt-3 ml-3 hidden-xs-only"
        small
        @click="removeFromCart"
      >
        <v-icon>mdi-minus</v-icon>Ta bort
      </v-btn>
    </v-list-item>
    <v-card-text class="hidden-sm-and-up">
      <v-btn class="error" small @click="removeFromCart">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    cartItem: Object,
    Index: Number,
  },
  data() {
    return {
      art: this.cartItem,
      edit: false,
    };
  },
  computed: {
    ...mapMutations(["setDefaultState"]),
    ...mapState({
      cart: (state) => state.cart.cart,
      state: (state) => state,
    }),
  },
  methods: {
    removeFromCart() {
      this.$emit("removeFromCart", this.cartItem);
    },
    saveChangesOnItem() {
      if (this.edit) {
        this.$store.commit("cart/setComment", this.cart);
        this.edit = false;
      } else {
        this.edit = true;
      }
    },
  },
};
</script>

<style></style>
