import services from "../../services";

const state = {
  shop: null
};

const getters = {
  shop: (state) => {
    return state.shop;
  },
  categories: (state) => {
    return state.shop.categories
  }
};

const actions = {
  getWebshop({ commit }, id) {
    return new Promise((resolve, reject) => {
      services.getWebshop(id).then(
        (shop) => {
          commit("setWebshop", shop);
          resolve(shop);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
};

const mutations = {
  setWebshop(state, shop) {
    state.shop = shop;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
