<template>
  <v-app id="application">
    <TopNav></TopNav>

    <v-main>
      <router-view></router-view>
      <CartButton :amountInCart="cartSize"></CartButton>

      <!--Snackbar-->
      <v-snackbar
        v-model="$store.state.snackbar.show"
        :top="true"
        :timeout="2600"
        :multi-line="true"
        :color="$store.state.snackbar.variant"
      >
        {{ $store.state.snackbar.message }}
        <v-btn text @click="$store.commit('updateSnackbar', { show: false })"
          >Close</v-btn
        >
      </v-snackbar>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TopNav from "./components/TopNav";
import Footer from "./components/home/Footer";
import CartButton from "./components/cart/CartButton";
export default {
  name: "App",
  components: {
    TopNav,
    CartButton,
    Footer,
  },
  watch: {
    shop(to) {
      document.title = `${to.name} | Retail Hut Webshop`;
    },
  },
  mounted() {},
  created() {
    this.$store.dispatch("webshop/getWebshop", this.$route.params.id);
    let existingCart = sessionStorage.getItem("cart");
    if (existingCart) {
      this.$store.dispatch("cart/setDefaultState", JSON.parse(existingCart));
    } else {
      sessionStorage.setItem("cart", JSON.stringify([]));
    }
  },
  computed: {
    ...mapState({
      shop: (state) => state.webshop.shop,
    }),
    ...mapGetters({
      cartSize: "cart/cartSize",
    }),
  },
};
</script>

<style>
#application {
  background-color: rgb(248, 248, 248)
}

h1 {
  color: #4d4d55;
}

h2 {
  color: #4d4d55;
}

h3 {
  color: #4d4d55;
}

h4 {
  color: #4d4d55;
}

h5 {
  color: #4d4d55;
}

h6 {
  color: #4d4d55;
}

p {
  color: #4d4d55;
}
</style>
