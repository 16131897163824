import api from "./api/api";
import axios from 'axios'

export default {
  //Get the webshop with all categories and articles
  getWebshop(id) {
    return api
      .get("webshop/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getArticle(id, articleId) {
    return api
      .get("webshop/" + id + "/article/" + articleId)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getOrder(id, orderNumber) {
    return api
      .get("webshop/" + id + "/order/" + orderNumber)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  submitOrder(id, order) {
    return api
      .post("webshop/" + id + "/SubmitOrder", order)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  confirmOrder(order) {
    let orderDTO = {
      orderNumber: order.orderNumber,
      paymentStatus: order.paymentMethod,
      creatorId : order.creatorId 
    };

    return api
      .post("webshop/ConfirmOrder", orderDTO)
      .then((response) => response.data);
  },
  updateOrder(order) {
    return api
      .post("webshop/UpdateOrder/" + order.orderNumber, order)
      .then((res) => res.data);
  },
  getServicePoint(city, lat, long) {
    const baseUrl = 'https://retailhutadminwebshopclient.azurewebsites.net/api/PickupLocation?code=MGwhQp5CGFUsGPQnrnOpByMHRsjJUR2anJUyzENs5uioMc6r1Yd/Lg=='
    
    let url = baseUrl + "&provider=dhl"
    if(city) {
      url += `&city=${city.toUpperCase()}`
    } 
    
    if (lat && long) {
      url += `&latitude=${lat}&longitude=${long}`
    }
    return axios.get(url).then(res => res.data)
  },
  create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
      c
    ) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }
};
