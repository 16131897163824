<template>
  <div class="ma-2">
    <v-container v-if="order">
      <v-row>
        <v-col class="text-center">
          <h2 class="display-2">Orderstatus</h2>
          <h2>{{ order.orderStatus }}</h2>
        </v-col>
      </v-row>

      <br />
      <v-row justify="center">
        <v-col lg="6">
          <v-divider class="mb-8"></v-divider>
          <div class="mb-8">
            <OrderInfo
              :articleList="order.orderArticles"
              :reciever="{ email: order.email, phone: order.phone }"
              :totalPrice="order.totalSum"
            ></OrderInfo>
          </div>
          <v-divider class="mb-8"></v-divider>
          <div class="mb-8">
            <Timeline></Timeline>
          </div>
          <v-divider class="mb-8"></v-divider>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col class="text-center">
          <h2 class="display-2">Denna order går inte att hitta</h2>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Timeline from "../components/order/Timeline";
import OrderInfo from "../components/order/OrderInfo";

export default {
  components: {
    Timeline,
    OrderInfo,
  },
  created() {
    this.$store.dispatch("order/getOrder", {
      id: this.$route.params.id,
      orderNumber: this.$route.params.orderNumber,
    });
  },
  computed: {
    ...mapState({
      order: (state) => state.order.order,
      shop: (state) => state.webshop.shop,
    }),
  },
};
</script>

<style></style>
