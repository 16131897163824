<template>
  <v-footer class="pa-6 py-12 footr" absolute app v-if="shop.isOnline">
    <v-row>
      <v-col>
        <p>Kontakt: <b>mats@ebreklam.se</b></p>
        <hr class="mb-2" />
        <div>
        <small> &copy; {{ new Date().getFullYear() }} {{ shop.name }} | Retail Hut</small>
        <br />
        <small>En produkt av Retail Hut. </small><br />
        <small
          >Vill du skapa enkla webshoppar till dina kunder? Kontakta
          info@retailhut.se för mer info</small
        >
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      shop: (state) => state.webshop.shop,
    }),
  },
};
</script>

<style>
</style>
