<template>
  <div>
    <v-timeline dense>
      <v-timeline-item
        v-for="item in order.orderHistory.slice().reverse()"
        :key="item.date"
        :icon="item.isDone ? 'mdi-check' : ''"
        :color="item.isDone ? 'secondary' : 'primary'"
      >
        <v-card :disabled="item.isDone">
          <div>
            <v-card-text v-if="item.isDone">{{ item.comment }}</v-card-text>
            <v-card-text v-else>{{ item.comment }}</v-card-text>
            <v-card-subtitle>{{ getDate(item.createdDate) }}</v-card-subtitle>
          </div>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import "moment/locale/sv";
export default {
  computed: {
    ...mapState({
      order: (state) => state.order.order,
      shop: (state) => state.webshop.shop,
    }),
  },

  methods: {
    getDate(date) {
      moment().locale("sv");
      return moment(date).format("llll:ss");
    },
  },
};
</script>

<style></style>
