<template>
  <div class="ma-2">
    <v-card>
      <v-divider></v-divider>
      <v-card-text hidden>
        <v-radio-group v-model="payment" :mandatory="true">
          <v-radio value="KLARNA">
            <template v-slot:label> Klarna </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>

      <v-card-text v-if="payment === 'KLARNA'">
        <div v-if="!loadingInvoice">
          <v-form v-model="validForm">
            <h2>Kontaktuppgifter</h2>
            <br />
            <v-text-field
              v-model="customer.email"
              prepend-inner-icon="mdi-mail"
              label="Epost"
              :rules="emailRules"
            ></v-text-field>
            <v-text-field
              v-model="customer.phone"
              prepend-inner-icon="mdi-phone"
              :counter="10"
              label="Telefon"
              :rules="phoneRules"
            ></v-text-field>
            
            <div v-if="shop.deliveryOptions != null && shop.deliveryOptions.length > 0">
              <v-radio-group v-model="deliveryOption" :mandatory="true">         
                <v-radio v-for="item in shop.deliveryOptions" v-bind:key="item.type" :value="item">
                  <template v-slot:label> {{item.name}} </template>
                </v-radio>    
              </v-radio-group>
              <div v-if="deliveryOption.type === 'PICKUP_SERVICE_POINT'">
              <v-card>
              <v-card-text>
                <div v-if="deliveryOption.provider === 'dhl'"> 
                  <img src="https://storageaccountweb.blob.core.windows.net/retailhut-images/static/logo-DHL.png"  class="ml-2"/> 
                  <pickup-point-selector @selectedPickupLocation="selectedPickupLocation" :rules="nameRules" />
                </div>
               
              <h3>Leveransuppgifter</h3>
              <v-text-field
                v-model="shippingInfo.firstName"
                prepend-inner-icon="mdi-account-box-multiple-outline"
                label="Förnamn"
                :rules="nameRules"
              ></v-text-field>
              <v-text-field
                v-model="shippingInfo.lastName"
                prepend-inner-icon="mdi-account-box-multiple-outline"
                label="Efternamn"
                :rules="nameRules"
              ></v-text-field>
                  <v-text-field
                v-model="shippingInfo.streetAddress"
                prepend-inner-icon="mdi-home-city"
                label="Address"
                :rules="nameRules"
              ></v-text-field>
              <v-text-field
                v-model="shippingInfo.postalCode"
                prepend-inner-icon="mdi-email"
                label="Postnummer"
                :rules="postalCodeRules"
              ></v-text-field>
              <v-text-field
                v-model="shippingInfo.city" 
                prepend-inner-icon="mdi-city-variant-outline" 
                label="Stad"
                :rules="nameRules"
              ></v-text-field>
              </v-card-text>
              </v-card>
            </div>
            </div>
            <div v-else>
              <br />
              <h2>Din beställning hämtas på plats hos leverantören</h2>
            </div>

            <v-switch
              v-model="customer.sendEmailsToClient"
              class="mx-2"
              label="Meddela mig orderstatus via epost"
            ></v-switch>

            <v-switch
              v-model="customer.acceptTerms"
              class="mx-2"
              label="Jag accepterar villkoren"
              :rules="termRules"
            ></v-switch>
            
              <terms-modal linkText="Läs villkoren"></terms-modal>
            <br />
          </v-form>
          <v-divider>
          </v-divider>
          <br />
          <v-btn
            @click="submitToKlarna"
            :disabled="!validFormWithDeliveryOption"
            class="primary"
            block
            v-if="TotalPrice > 0"
            >Betala med
            <img
              alt="Klarna"
              src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"
              class="ml-2"
          /></v-btn>
        </div>
        <div v-else>
          <div class="text-center">
            <v-progress-circular
              indeterminate
              color="secondary"
            ></v-progress-circular>
          </div>
        </div>
      </v-card-text>
      <div v-if="deliveryOption.type == 'PICKUP_SERVICE_POINT'">   
        <div v-if="TotalPrice <= freeShippingLimit">
        
          <v-card-subtitle>
            Pris: {{ TotalPrice }}:-  <br />
            Frakt: {{shippingFee}} :-         
          </v-card-subtitle>
          <v-card-text>Vid köp av över {{freeShippingLimit}} :- ingår gratis frakt</v-card-text>
          <v-card-title>Totalt: {{ TotalPrice + shippingFee }} :-</v-card-title>

        </div>
        <div v-else>
          <v-card-subtitle>
            Pris: {{ TotalPrice }}:-  <br />
            <span class="text-decoration-line-through">Frakt: {{shippingFee}} :-</span>     
          </v-card-subtitle>
          <v-card-title>Totalt: {{ TotalPrice }} :-</v-card-title>
           <v-card-title class="text--green">Du har fått gratis frakt!</v-card-title>
      </div>
      </div>
      <div v-else>
          <v-card-title>Totalt: {{ TotalPrice }} :-</v-card-title>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TermsModal from "../TermsModal.vue";
import PickupPointSelector from "../cart/PickupPointSelector.vue"
export default {
  props: {
    TotalPrice: Number,
  },
  components: {
    TermsModal,
    PickupPointSelector
  },
  mounted() {
    const customerData = localStorage.getItem(`${this.shop.slug}-customer`, JSON.stringify(this.customer))
    if (customerData) {
      this.customer = JSON.parse(customerData)
    }

     let shippingInfoString = localStorage.getItem(`${this.shop.slug}-shippingInfo`)
        if (shippingInfoString) {
          this.shippingInfo = JSON.parse(shippingInfoString)
        }
  },
  methods: {
    async submitOrder() {
      const order = this.generateOrder();
      console.log(order)
      const slug = this.shop.slug;

      return await this.$store.dispatch("order/createOrder", { slug, order });
    },
    generateOrder() {
      return {
        articles: this.cart,
        name: this.shop.name,
        paymentMethod: this.payment,
        paymentStatus: "Pending",
        deliveryMethod: this.deliveryOption.type,
        email: this.customer.email,
        phone: this.customer.phone,
        organizationNumber: this.customer.orgnumber,
        sendEmailsToClient: this.customer.sendEmailsToClient,
        shippingInfoJson : JSON.stringify(this.shippingInfo)
      };
    },
    submitInvoice() {
      this.loadingInvoice = true;
      this.submitOrder()
        .then((res) => {
          this.$router.push(`/${this.shop.slug}/order/${res.order_number}`);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingInvoice = false;
        });
    },
    submitToKlarna() {
      this.loadingInvoice = true;
      this.submitOrder()
        .then((res) => {
          if (res.redirect_url) {
            localStorage.setItem("slug", this.shop.slug);
            window.location.href = res.redirect_url;
          }
        })
        .catch((err) => {
          alert(err);
          this.loadingInvoice = false;
        })
        .finally(() => {});
    },
    selectPayment(item) {
      this.selectPayment = item;
    },
    selectedPickupLocation(e) {
      this.shippingInfo.selectedPickupLocation = e

    },
    savePersonalDetailsToStorage() {
      localStorage.setItem(`${this.shop.slug}-shippingInfo`, JSON.stringify(this.shippingInfo))
      localStorage.setItem(`${this.shop.slug}-customer`, JSON.stringify(this.customer))
    }
  },
  data() {
    return {
      selectedPayment: "",
      payment: "",
      deliveryOption: "",
      validForm: false,
      loadingInvoice: false,
      customer: {
        email: "",
        phone: "",
        orgnumber: "",
        acceptTerms: false,
        sendEmailsToClient: true,
      },
      shippingInfo : {
        firstName : "",
        lastName: "",
        postalCode: "",
        streetAddress: "",
        city: "",
        selectedPickupLocation : null,
      },
      emailRules: [
        (v) => !!v || "Ange din Epostadress!",
        (v) => /.+@.+/.test(v) || "Ej giltig epost",
      ],
      phoneRules: [
        (v) => !!v || "Ange ditt telefonnummer!",
        (v) => v.length <= 10 || "Ej giltigt telefonnummer",
        (v) => !isNaN(v) || "Ange endast siffror!",
      ],
      nameRules: [
        (v) => !!v || "Du måste ange detta fält!",
      ],
      postalCodeRules: [
        (v) => !!v || "Ange ditt postnummer!",
        (v) => v.length <= 6 || "Ej giltigt postnummer",
        (v) => !isNaN(v) || "Ange endast siffror!"
      ],

      termRules: [(v) => !!v || "Du måste acceptera villkoren"],
    };
  },
  watch: {
    'customer.phone' : function() {
      this.savePersonalDetailsToStorage()
    },
    'customer.email' : function() {
      this.savePersonalDetailsToStorage()
    },
    'shippingInfo.firstName': function() {
        this.savePersonalDetailsToStorage()
    },
    'shippingInfo.lastName': function() {
      this.savePersonalDetailsToStorage()
    },
    'shippingInfo.postalCode': function() {
      this.savePersonalDetailsToStorage()
    },
    'shippingInfo.streetAddress': function() {
      this.savePersonalDetailsToStorage()
    },
    'shippingInfo.city': function() {
      this.savePersonalDetailsToStorage()
    },
    'deliveryOption.type': function(newVal) {
      if(newVal === 'PICKUP_SERVICE_POINT') {
        let shippingInfoString = localStorage.getItem(`${this.shop.slug}-shippingInfo`)
        if (shippingInfoString) {
          this.shippingInfo = JSON.parse(shippingInfoString)
        }
      }   
    }
  },
  computed: {
    ...mapState({
      shop: (state) => state.webshop.shop,
      cart: (state) => state.cart.cart,
      order: (state) => state.order.order,
      shippingFee: (state) => state.order.shippingFee,
      freeShippingLimit: (state) => state.order.freeShippingLimit
    }),
    canBuyWithCard() {
      return this.phoneRules && this.emailRules;
    },
    validFormWithDeliveryOption() {
      if (this.shop.deliveryOptions == null) return this.validForm;
      return this.shop.deliveryOptions.length < 1 || this.validForm
    }
  }
};
</script>

<style></style>
