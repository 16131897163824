<template>
  <div>
    <v-container mb-12>
      <v-row>
        <v-col sm="10" offset-sm="1" lg="8" md="12" xl="10" offshet-lg="2">
          <v-col>
            <p v-if="shop.withVat">Alla priser är inklusive moms</p>
            <p v-else>Alla priser är exklusive moms</p>

            <p>Kategorier</p>
            <v-radio-group
              v-model="categories"
              :mandatory="true"
              row
              class="hidden-xs-only"
            >
              <v-radio label="Alla" value="Alla" selected></v-radio>
              <v-radio
                v-for="item in shop.categories"
                :label="item.name"
                :key="item.name"
                :value="item.name"
              ></v-radio>
            </v-radio-group>

            <v-radio-group
              v-model="categories"
              :mandatory="true"
              class="hidden-sm-and-up"
            >
              <v-radio label="Alla" value="Alla" selected></v-radio>
              <v-radio
                v-for="item in categoryList"
                :label="item.name"
                :key="item.name"
                :value="item.name"
              ></v-radio>
            </v-radio-group>
            <small v-if="filtered && shop.allArticles">
              Visar {{ filtered.length }} artiklar av
              {{ shop.allArticles.length }}
            </small>
            <v-divider></v-divider>
          </v-col>
          <v-row>
            <!--Articles -->
            <v-col
              sm="6"
              md="4"
              lg="4"
              xl="2"
              v-for="article in filtered"
              :key="article.id"
            >
              <VerticalCard :article="article"></VerticalCard>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VerticalCard from "../cards/VerticalCard";
import { mapState } from "vuex";

export default {
  components: {
    VerticalCard,
  },
  data() {
    return {
      categories: "Alla",
      complex: null,
    };
  },
  mounted() {
    console.log();
  },
  computed: {
    ...mapState({
      shop: (state) => state.webshop.shop,
    }),
    categoryList() {
      let c = this.$store.getters["webshop/categories"];
      return c.sort((a, b) => a.name.localeCompare(b.name));
    },
    filtered() {
      if (this.categories === "Alla") return this.shop.allArticles;
      return this.shop.allArticles.filter((item) => {
        return item.categoryName === this.categories;
      });
    },
  },
};
</script>

<style></style>
