<template>
  <div v-if="shop">
    <v-app-bar class="top-bar">
      <v-toolbar-title>
        <v-row class="hidden-sm-and-down">
          <v-col>
            <v-avatar>
              <img />
              <v-img :src="shop.webshopLogoImage" />
            </v-avatar>
          </v-col>

          <v-col class="mt-2">
            <span>{{ shop.name }}</span>
          </v-col>
        </v-row>
        <div class="hidden-md-and-up">{{ shop.name }}</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <span class="hidden-sm-and-up">
        <v-btn @click.stop="drawer = !drawer">Meny</v-btn>
      </span>

      <v-toolbar-items class="hidden-xs-only">
        <v-btn @click="goHome">
          <v-icon small left>mdi-home</v-icon>Start
        </v-btn>

        <v-btn @click="goToCart" text v-if="shop.isOnline">
          <v-icon small left>mdi-cart</v-icon>Kundvagn
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <!--Mobile -->
    <v-navigation-drawer v-model="drawer" right fixed temporary>
      <v-list>
        <v-list-item @click="goHome">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Start</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="goToCart" v-if="shop.isOnline">
          <v-list-item-icon>
            <v-icon>mdi-cart</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Kundvagn</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapState({
      shop: (state) => state.webshop.shop,
    }),
  },
  methods: {
    goHome() {
      this.$router.push("/" + this.shop.slug);
    },

    goToCart() {
      this.$router.push("/" + this.shop.slug + "/cart");
    },
  },
};
</script>

<style>
.top-bar {
  margin-bottom: 5px;
}
</style>
