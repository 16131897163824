import services from "../../services";
import * as _ from "underscore";
const getDefaultState = () => {
    return {
      cart: []
    }; 
};

const state = getDefaultState();

const getters = {
  cartSize: (state) => {
    return state.cart.length;
  },

  cartTotalAmount: (state) => {
    return state.cart.reduce((amount, item) => item.price + amount, 0);
  },

  cartCompact: (state) => {
    return _.uniq(state.cart, (i) => {
      return i.name && i.selectedVariant;
    });
  },

  cartItemListQuantity: (state, item) => {
    return state.cart.filter(
      (i) => i.name === item.name && i.selectedVariant === item.selectedVariant
    ).length;
  }
};

const actions = {
  setDefaultState: ({ commit }, sessionStorageCart) => {
    commit("setDefaultState", sessionStorageCart);
  },

  addToCart: ({ commit }, article) => {
    commit("addToCart", article);
  },

  removeFromCart: ({ commit }, articleId) => {
    console.log(commit, articleId);
  },

  deleteFromCart: ({ commit }, index) => {
    commit("deleteFromCart", index);
  },
  cartItemQuantity: ({ commit }, item) => {
    commit("cartItemQuantity", item);
  }
};

const mutations = {
  setDefaultState: (state, sessionStorageCart) => {    
    state.cart = sessionStorageCart
  },

  setComment : (state, commentedCart) => {
    state.cart = commentedCart
    sessionStorage.setItem("cart", JSON.stringify(commentedCart))
  },

  addToCart: (state, article) => {
    //Creating a new object so it wont reference to its parent

    const imageSrc = () => {
      if (article.selectedVariant.variantImageThumb) {
        return article.selectedVariant.variantImageThumb
      }

      if (article.article.articleImageThumb) {
        return article.article.articleImageThumb
      }

      if (article.selectedVariant.variantImage) {
        return article.selectedVariant.variantImage
      }

      return article.article.articleImage         
    };

    let art = {
      id: article.article.id,
      name: article.article.name,
      selectedVariant: article.selectedVariant.name,
      selectedVariantId: article.selectedVariant.id,
      image: imageSrc(),
      cartId: services.create_UUID(),
      comment: article.article.comment,
      variantImage: article.article.articleImage,
      price: article.article.price,
      quantity: article.quantity,
      isCommentable: article.article.isCommentable,
      commentName : article.article.commentName
    };

    state.cart.push(art);
    const lCart = JSON.parse(sessionStorage.getItem("cart"))
    if(lCart) {
      lCart.push(art)
      sessionStorage.setItem("cart", JSON.stringify(lCart))
    }
  },

  deleteFromCart: (state, index) => {
    state.cart.splice(index, 1);
    const lCart = JSON.parse(sessionStorage.getItem("cart"))
    if(lCart) {
      lCart.splice(index, 1)
      sessionStorage.setItem("cart", JSON.stringify(lCart))
    }

  },
  cartItemQuantity: (state, item) => {
    return state.cart.filter(
      (i) => i.name === item.name && i.selectedVariant === item.selectedVariant
    ).length;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
