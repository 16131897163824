<template>
  <v-sheet :height="sheetHeight">
    <v-img :src="header" class="fill-height d-flex" :height="sheetHeight">
      <v-container fluid fill-height>
        <v-row>
          <v-col>
            <v-row v-show="shop.webshopLogoImage !== null">
              <v-col>
                <v-row>
                  <v-img
                    :src="shop.webshopLogoImage"
                    height="85"
                    contain
                  ></v-img>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <h1 class="display-3 white--text text-shadow hidden-sm-and-down">
                {{ Name }}
              </h1>
              <h1 class="display-1 white--text hidden-md-and-up text-shadow">
                {{ Name }}
              </h1>
            </v-row>
            <v-row
              align="center"
              justify="center"
              class="mt-4 white--text text-shadow hidden-sm-and-down text-center"
              >{{ Description }}</v-row
            >
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    Name: String,
    Description: String,
  },
  computed: {
    sheetHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "200px";
        case "sm":
          return "300px";
        case "md":
          return "300px";
        case "lg":
          return "300px";
        case "xl":
          return "350px";
      }
      return "300px";
    },
    ...mapState({
      shop: (state) => state.webshop.shop,
    }),

    header() {
      if (this.shop.headerImage === null) {
        return "https://image.freepik.com/free-vector/elegant-white-texture-background_23-2148431731.jpg";
      } else {
        return this.shop.headerImage;
      }
    },
  },
};
</script>

<style>
.text-shadow {
  text-shadow: 3px 2px 9px rgba(0, 0, 0, 0.75);
}
</style>
