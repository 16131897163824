import Vue from "vue";
import Vuetify from "vuetify/lib";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify);

const light = {
  primary: "#1976D2",
  secondary: "#424242",
  accent: "#82B1FF",
  error: "#FF5252",
  info: "#2196F3",
  success: "#4CAF50",
  warning: "#FFC107",
  textColor: "#403a3a"
};

//#1976D2

export default new Vuetify({
  icons: {
    iconfont: "md"
  },
  theme: {
    themes: { light }
  }
});
